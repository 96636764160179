<template>
  <div class="shoppingcar">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <!-- 头部 -->
    <section class="shopcar-header dark_bg dark_text dark_border">
      <em
        class="iconfont icon-fanhui-copy"
        @click="goBack"
        v-if="showBack"
      ></em>
      <em v-else></em>
      <h1 class="title">购物车</h1>
      <p class="administration" @click="managementCart">管理</p>
    </section>
    <!-- 购物车列表 -->
    <!-- 结算 -->
    <div class="shopcontent">
      <van-submit-bar
        :price="totalPrice"
        :button-text="submitBarText"
        @submit="onSubmit"
        id="submit-bar1"
        v-if="!showDel"
        class="dark_shop_bg_over dark_border"
      >
        <!-- 列表全选 -->
        <van-checkbox
          v-model="checkAll"
          @click="checkAllgoods"
          class="dark_text"
          >全选</van-checkbox
        >
      </van-submit-bar>

      <van-submit-bar
        :price="totalNums"
        button-text="删除"
        id="submit-bar2"
        v-else
        @submit="delSubmit"
        currency
        :decimal-length="0"
        suffix-label="件商品"
        class="del-handler dark_shop_bg_over dark_border"
      >
        <!-- 管理的全选 -->
        <van-checkbox
          v-model="checkAll"
          @click="checkAllgoods"
          class="dark_text"
          >全选</van-checkbox
        >
      </van-submit-bar>

      <van-checkbox-group
        v-model="checkedGoods"
        ref="checkboxGroup"
        @change="Onchange"
        v-if="goods.length"
        class='shopcar-list'
      >
        <van-checkbox
          v-for="(item, gindex) in goods"
          :key="item.id"
          :name="item.id"
          ref="checkboxs"
          label-disabled
          class="dark_shop_bg"
        >
          <div
            class="row-box"
            @click="goGoodsDetail(item.spuId, item.status, item.companyId)"
          >
            <div class="col-box">
              <img :src="item.spuImg" class="spuImgStyle" />
            </div>
            <div class="right-box">
              <h1 class="dark_text">{{ item.title }}</h1>
              <p class="goods-spec">{{ item.skuTitle }}</p>
              <div class="price_count">
                <em v-if="!item.price">
                  ￥{{ (item.price / 100).toFixed(2) }}
                  <span
                    style="
                      font-size: 0.6rem;
                      color: #999;
                      text-decoration: line-through;
                    "
                    >￥:{{ (item.price / 100).toFixed(2) }}</span
                  >
                </em>
                <em class="price_top" v-else style="font-size: 0.34rem"
                  ><span style='font-size:.2rem'>￥</span>{{ (item.price / 100).toFixed(2) }}</em
                >
                <em style="font-size: 0.65rem" v-if="item.score"
                  >{{ item.score }}积分</em
                >
                <div class='money-list'>
                  <!-- <span
                    class="iconfont icon-jianqu dark_shop_icon "
                    @click.stop="reduceCount(gindex, item.skuId)"
                    :style="{
                      color: item.num == 1 ? 'rgb(154, 149, 149)' : 'black',
                    }"
                  ></span> -->
                  <van-icon @click.stop="reduceCount(gindex, item.skuId)"
                  class='fh'
                    :style="{
                      color: item.num == 1 ? 'rgb(154, 149, 149)' : 'black' 
                    }" name="minus" />
                  <span class="dark_text_num box-border">{{ item.num }}</span>
                  <van-icon name="plus" class='fh'  @click.stop="addCount(gindex, item.skuId)"/>
                  <!-- <span
                    class="iconfont icon-tianjia-yin dark_shop_icon "
                    @click.stop="addCount(gindex, item.skuId)"
                  ></span> -->

                </div>
              </div>
            </div>
          </div>
        </van-checkbox>
      </van-checkbox-group>
      <p v-else class="emptyCar">
        <img
          src="../../../static/images/cartempty.png"
          style="width: 60%; margin: 0 20%"
          alt
        />
        <span class="shop-car-title">购物车空空如也，去逛逛吧~</span>
      </p>
    </div>
    <!-- 用户没有地址时的弹窗提示 -->
    <van-dialog v-model="showAddress" :show-confirm-button="false">
      <p class="addressTip">您还没有设置收货地址</p>
      <div class="handleBtn">
        <p style="color: #ce4434" @click="cancleHandle">取消</p>
        <p
          style="background-color: #ce4434; color: #fff"
          @click="confirmHandle"
        >
          去设置
        </p>
      </div>
    </van-dialog>
    <Lovely  name='猜你喜欢' style="min-height: 5rem" class="like"/>
    <NavBar v-if='!storeId' activeFlag='2'/>
    <NavBarAlone v-else activeFlag='2'/>

  </div>
</template>

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Row,
  Col,
  Dialog,
} from "vant";
import Lovely from "./lovely";
let accessToken = localStorage.getItem("token");
export default {
  data() {
    return {
      storeId:localStorage.storeId,
      checkedGoods: [], // 各个商品的选中状态,默认是用商品的id
      checkAll: false, // 控制是否全选
      goods: ["0"],
      isLoading: true,
      goGoodsDetail: this.Base.goGoodsDetail,
      showDel: false,
      showBack: false,
      showAddress: false, // 是否显示添加地址提示,
    };
  },
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dialog.Component.name]: Dialog.Component,
    Lovely,
  },
  created() {
    if (!localStorage.getItem("appid")) {
      this.getConfigAppId();
    }
    this.getLoad();
    if (this.$route.query.goodsdetail) {
      this.showBack = true;
    } else {
      this.showBack = false;
    }
  },
  computed: {
    // 提交按钮的文字设置
    submitBarText() {
      let goodsNum = 0;
      this.goods.forEach((val, i) => {
        if (this.checkedGoods.indexOf(val.id) != -1) {
          goodsNum += val.num;
        }
      });
      return "结算" + (goodsNum ? `(${goodsNum})` : "");
    },
    // 合计价格
    totalPrice() {
      var totalmoney = 0;
      this.goods.forEach((val, i) => {
        if (this.checkedGoods.indexOf(val.id) != -1) {
          if (!val.discountMoney) {
            // 如果不存在折扣价
            totalmoney += val.num * ((Number(val.price) * 100) / 100);
          } else {
            // 如果存在折扣价
            totalmoney += val.num * ((Number(val.discountMoney) * 100) / 100);
          }
        }
      });
      return totalmoney;
    },
    // 合计积分
    totalCores() {
      var totalcore = 0;
      this.goods.forEach((val, i) => {
        if (this.checkedGoods.indexOf(val.id) != -1) {
          totalcore += val.num * Number(val.score);
        }
      });
      return `合计：${totalcore}积分`;
    },
    // 总商品件数
    totalNums() {
      let goodsNum = 0;
      this.goods.forEach((val, i) => {
        if (this.checkedGoods.indexOf(val.id) != -1) {
          goodsNum += val.num * 100;
        }
      });
      return goodsNum;
    },
  },
  methods: {
    getConfigAppId() {
      this.$api.login
        .getConfigLogoUrl({
          k: "APPID",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
             
            localStorage.setItem("appid", res.data.valueWorth);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    // 去到地址列表
    confirmHandle() {
      this.$router.push("/addresslist");
    },
    // 取消设置地址
    cancleHandle() {
      this.showAddress = false;
    },
    // 返回
    goBack() {
      this.$router.back();
    },
    // 结算按钮提交事件
    onSubmit() {
      // 如果未选中商品
      if (!this.checkedGoods.length) {
        this.$toast("请至少选择一个商品");
      } else {
        let orderList = [];
        this.goods.forEach((val, i) => {
          if (this.checkedGoods.indexOf(val.id) != -1) {
            orderList.push({
              id: val.id,
              img: val.spuImg, // 商品图片
              title: val.title, // 商品标题
              skuTitle: val.skuTitle, // 商品规格
              num: val.num, //商品数量
              price: val.price, //商品价格
              skuId: val.skuId, //商品规格id
              spuId: val.spuId, //商品id
              isshowcar: true,
              takeWay: "buy",
            });
          }
        });
        // 发起请求创建订单
        // this.$server.orderApi
        //   .preOrderAddress({
        //     accessToken: localStorage.getItem("token"),
        //     orderList: JSON.stringify(orderList),
        //   })
        //   .then((res) => {
        //     if (res.code === 100) {
        // 把购物车id存入本地
        // let cartList = JSON.stringify(orderList);
        // sessionStorage.setItem("cartList", cartList);

        // this.$router.push({
        //   name: 'orderconfirm',
        //   params: {
        //     orderId: res.returnValue.orderId,
        //     cartList
        //   }
        // });
        // this.$router.push(`/orderconfirm/${res.returnValue.orderId}`);
        // this.$router.push(`/orderconfirm`);
        //   } else if (res.code === 141) {
        //     this.showAddress = true;
        //   } else {
        //     this.$toast(res.message);
        //   }
        // });

        localStorage.setItem("skuGoodsList", JSON.stringify(orderList));
        // console.log(
        //   "购物车 取skuGoodsList",
        //   JSON.parse(localStorage.getItem("skuGoodsList"))
        // );
        this.$router.push({
          path: "/orderconfirm",
        });
        // console.log("this.goods", this.goods);
        // console.log("this.checkedGoods", this.checkedGoods);
      }
    },
    // 管理购物车
    managementCart() {
      this.showDel = !this.showDel;
    },
    // 购物车批量删除
    delSubmit() {
      Dialog.confirm({
        title: '标题',
        message: '是否确认删除?',
      })
        .then(() => {
          
          
            if (this.checkedGoods.length) {
        let listCartId = [];
        this.goods.forEach((val, i) => {
          if (this.checkedGoods.indexOf(val.id) != -1) {
            listCartId.push(val.id);
          }
        });

        let arr = "";
        if (listCartId.length > 1) {
          listCartId.forEach((v, i) => {
            if (i === listCartId.length - 1) {
              arr += v;
            } else {
              arr += v + ",";
            }
          });
        } else {
          arr += listCartId[0];
        }
        this.$api.shopcar
          .delMultileShopCarAddress({
            cartIdList: JSON.stringify(arr),
            userId: localStorage.getItem("customerId"),
          })
          .then((res) => {
            if (res.errno == 200) {
              // 清空选中
              this.checkedGoods = [];
              // 删除提示
              this.$toast.success({
                message: "删除成功",
                duration: 1000,
                onClose: () => {
                  // 过滤this.goods,过滤掉已经被选中删除的goods
                  this.goods = this.goods.filter((item) => {
                    // 最终返回没有在选中删除的列表里的物品
                    return listCartId.indexOf(item.id) == -1;
                  });
                },
              });
            } else {
              this.$toast(res.errmsg);
            }
          });
      } else {
        this.$toast("请至少选择一个商品");
      }
        })
        .catch(() => {
          // on cancel
        });
    
    },
    // 增加商品数量
    addCount(index, id) {
      this.goods[index].num++;
      // 修改数量
      this.$api.shopcar
        .goodsNumCarAddress({
          userId: localStorage.getItem("customerId"),
          skuId: id,
          num: 1,
          // num: this.goods[index].num,
        })
        .then((res) => {
          if (res.errno === 200) {
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    // 减少商品数量
    reduceCount(index, id) {
      // console.log("减少商品数量", this.goods, this.goods[index].num);
      if (this.goods[index].num > 1) {
        this.goods[index].num--;
        // 修改数量
        this.$api.shopcar
          .goodsNumCarSub({
            userId: localStorage.getItem("customerId"),
            skuId: id,
            num: 1,
            // num: this.goods[index].num,
          })
          .then((res) => {
            if (res.errno === 200) {
            } else {
              this.$toast(res.message);
            }
          });
      } else {
        this.$toast("宝贝不能再少了哟");
      }
    },
    // 复选组的change事件监听，可以监听到单个商品的选中状态
    Onchange() {
      // 如果选中了所有商品，全选按钮就点亮
      if (this.checkedGoods.length == this.goods.length) {
        this.checkAll = true;
      } else {
        // 如果有一个级以上商品没有选中，就取消全选按钮的颜色
        this.checkAll = false;
      }
    },

    // clickchange() {
    // },
    // 全选按钮监听的点击事件
    checkAllgoods(e) {
      // console.log(e);

      if (this.checkAll) {
        this.goods.forEach((v, i) => {
          // 判断每个物品是否已经是选中状态，当点击全选的时候，是选中状态就保留，不是就加上，当值为-1的时候，代表是没有选中的，其他值都是选中了
          if (this.checkedGoods.indexOf(v.id) == -1) {
            this.checkedGoods.push(`${v.id}`);
          }
          this.$refs.checkboxGroup.toggleAll(true);
        });
      } else {
        // 取消全选
        this.checkedGoods = [];
        this.$refs.checkboxGroup.toggleAll();
      }

      this.checkAll = !this.checkAll;
    },
    // 获取购物车列表
    getLoad() {
      this.$api.shopcar
        .shopCarListAddress({
          userId: localStorage.getItem("customerId"),
          storeId:localStorage.storeId,

        })
        .then((res) => {
          if (res.errno === 200) {
            this.goods = res.data;
            this.isLoading = false;
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../less/element.less";
// @red: #c83f3f;
// .shoppingCar {
//   width: 100%;
//   .shopcar-header {
//     font-size: 0.1rem;
//   }
// }
.shoppingcar {
  height: 100vh;
  // background-color: #f0eded;
  background-color: #f6f6f9;
  margin: 1rem 0;
}
.shopcar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.3rem;
  font-size: 0.3rem;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
  .title {
    font-size: 0.4rem;
    font-weight: normal;
  }
  .administration {
    float: right;
    font-size: 0.3rem;
  }
}
em {
  font-size: 0.3rem;
}
.dark_text {
  // font-size: 0.26rem;
  // font-weight: normal;
  font-size: .28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: .32rem;
}
.dark_text_num {
  margin: 0 0.4rem;
  font-size: 0.24rem;
  line-height: .32rem;

  
}
.goods-spec {
  margin-bottom: .24rem !important;
  font-size: 0.23rem;
  color: #7E7E7E;
   display: inline-block;
    // border: 1px solid #ccc;
    padding: .08rem .1rem .05rem;

    background: #F4F4F4;
    border-radius: .05rem;
}
.dark_shop_bg {
  margin: 0.1rem .3rem 0;
  padding:.4rem 0.3rem .3rem;
  background-color: #fff;
  box-shadow: 0px 0px .1rem 0px rgba(0, 0, 0, 0.05);
  border-radius: .2rem;
}
.dark_shop_bg_over{
  margin: 0.1rem .3rem 0;
  background-color: #fff;
  box-shadow: 0px 0px .1rem 0px rgba(0, 0, 0, 0.05);
  border-radius: .2rem;
}
/deep/.van-checkbox__label {
  width: 90%;
}
.row-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.col-box {
  margin-right: 15px;
  // width: 1.6rem;
  // height: 1.6rem;
}
.right-box {
  width: 100%;
}
.spuImgStyle {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: .1rem;
}
// .dark_border {
// }
.price_count {
  display: flex;
  justify-content: space-between;
  height: .36rem;
  padding-bottom: .16rem;
}
.price_top {
  // padding-top: 0.24rem;
  // color: @red;

  font-size: .34rem;
font-weight: bold;
color: @price_text_color;
}

.shop-car-title {
  display: block;
  margin-left: 24%;
  font-size: 0.3rem !important;
}
.van-submit-bar {
  // padding: 0;
  // margin-bottom: 1rem;
  margin-bottom: 1.08rem /* 54/50 */;
}
.shoppingCar {
  width: 100%;
  box-sizing: border-box;
  // padding: 3.5rem 0 6rem;
  .shopcar-header {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    padding-bottom: constant( safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env( safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    top: 0;
    z-index: 99;
    left: 0;
    .list(row);
    padding: 1rem;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
    letter-spacing: 1px;
    font-size: 0.1rem;
    border-bottom: 1px solid #e2dfdfa1;
    text-align: center;
    & > p {
      font-size: 0.1rem;
    }
  }
  & > div.shopcontent {
    .del-handler {
      .van-submit-bar__button {
        width: 5rem;
        box-sizing: border-box;
      }
      .van-submit-bar__bar .van-button {
        background-color: #fff;
        // color: #c83f3f;
        border-radius: 2.4rem;
        letter-spacing: 2px;
      }
    }
    .van-submit-bar {
      bottom: 2.8rem;
      border-bottom: 1px solid #f0f0f0;
    }
    #submit-bar2 {
      /deep/ .van-submit-bar__price {
        font-size: 100%;
      }
    }
    .van-submit-bar__bar {
      align-items: center;

      .van-checkbox {
        margin: 0;
      }
      // .van-submit-bar__price {
      //   color: @price_text_color !important;
      // }
      .van-button {
        background-color: @ordinary_btn_color;
        border: 1px solid @ordinary_btn_border_color;
        letter-spacing: 1px;
        margin: 0 0.5rem 0 0;
        height: 2rem;
        line-height: 2rem;
        border-radius: 0.2rem;
      }
    }
    .van-checkbox-group {
      .van-checkbox {
        background-color: #fff;
      }
    }
    .van-checkbox {
      margin: 0 0 0.2rem;
      .list(row);
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      .van-checkbox__label {
        .van-col {
          font-size: 0.9rem;
          img {
            width: 100%;
          }
          &:nth-child(2) {
            h1 {
              font-size: 0.9rem;
              color: black;
              margin: 0 0 0.4rem 0;
            }
            .goods-spec {
              display: inline-block;
              padding: 0.1rem 0.2rem;
              background-color: rgba(236, 236, 236, 0.6);
              color: rgb(153, 153, 153);
              border-radius: 3px;
              font-size: 0.1rem;
            }
            .price_count {
              .list(row);
              justify-content: space-between;
              em {
                color: @price_text_color;
              }
              & > p {
                .list(row);
                justify-content: space-between;
                span {
                  font-size: 1.2rem;
                  margin: 0 0.3rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .van-dialog {
    width: 60%;
    background: url("../../../static/images/noaddress.png") no-repeat;
    background-size: 100% 100%;
    .addressTip {
      width: 100%;
      box-sizing: border-box;
      padding: 160px 10px 40px;
      line-height: 24px;
      text-align: center;
    }
    .handleBtn {
      .list(row);
      padding: 0 1rem 1rem;
      justify-content: space-between;
      background-color: #fff;
      & > p {
        border: 1px solid @ordinary_btn_border_color;
        font-size: 0.9rem;
        text-align: center;
        border-radius: 2rem;
        letter-spacing: 1px;
        width: 46%;
        padding: 0.4rem 0;
      }
    }
  }


  // p.emptyCar {
  //   margin: 1rem 0 1rem;
  //   text-align: center;
  //   img {
  //     width: 60%;
  //   }
  //   span {
  //     display: block;
  //     margin: 1rem 0 0;
  //   }
  // }
}

// 适配IPoneX底部样式
// @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
//   #submit-bar1,
//   #submit-bar2 {
//     bottom: 3.9rem;
//     .title {
//       font-weight: normal;
//     }
//   }
// }
.money-list{
  display: flex;
  justify-content: space-between;
  padding: .1rem;
  height: .26rem;
  border-collapse:collapse;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: .05rem;

}
.box-border{
  position: relative;
  
  &::after{
    position: absolute;
    top: -.11rem;
    left: -.30rem;
    content: '';
    width: 1px;
    height: .48rem;
    background: #C4C4C4;
  }
   &::before{
    position: absolute;
    top: -.11rem;
    right: -.30rem;

     display: block;
    content: '';
    width: 1px;
    height: .48rem;

    background: #C4C4C4;
  }

}
.fh{
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: .24rem;
  line-height: .26rem;
}
/deep/ .van-submit-bar{
  left: -.3rem;
}
/deep/ .van-submit-bar__button--danger{
  width: 2.1rem;
  height: .7rem;
  // background: linear-gradient(0deg, #EE3A3A, #F46161);
  background: @ordinary_btn_border_color;
  border-radius: .1rem;
  font-size: .28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: .49rem;
}
.shopcar-list{
  padding-top: .06rem !important;
}
.van-submit-bar__price {
  color: @price_text_color !important;
}
// 猜你喜欢
.like {
  padding-bottom: 105px !important;
}
</style>
<style >
    body{
        background-color: #f6f6f6;
    }
</style>

